@use 'vars';
@use 'mixins';

.btn {
  @include mixins.border-radius(3px);
  @include mixins.transition(background 0.2s ease);
  background-color: vars.$green;
  color: #FFF;
  font-weight: bold;
  padding: 8px 15px;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 1.3rem;
  &:hover {
    background-color: vars.$green-hover;
  }
  &:active {
    @include mixins.box-shadow(inset 0 0 2px 1px rgba(0, 0, 0, 0.2));
  }
  &.disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  &.small {
    padding: 2px 10px;
  }
  &.yellow{
    background-color: vars.$yellow;
    &:hover {
      background-color: vars.$light-yellow;
      .icon {
        background-color: vars.$light-yellow;
      }
    }
  }

  &.red{
    background-color: vars.$red;
    &:hover {
      background-color: vars.$light-red;
      .icon {
        background-color: vars.$light-red;
      }
    }
  }
  &.no-after {
    &::after {
      display: none;
    }
  }
  .icon {
    vertical-align: middle;
  }
  &.clean-btn {
    background: none;
    color: #999;
    &:hover {
      color: #666;

      .icon {
        color: #666;
      }
    }
    &.green{
      color: vars.$green;
      &:hover {
        color: vars.$green-hover;

        .icon {
          color: vars.$green-hover;
        }
      }
    }
    &:active {
      @include mixins.box-shadow(none);
    }
  }
  &.secondary {
    background: none;
    color: vars.$green;
    .icon {
      color: vars.$green;
    }
  }
}

.table-link {
  @include mixins.border-radius(3px);
  background-color: vars.$green;
  color: #FFF;
  text-decoration: none;
  padding: 5px 10px;
  margin: 0 2px;
  display: inline-block;

  &:hover,
  &.viewed {
    background-color: vars.$light-yellow;
  }

  .icon {
    vertical-align: middle;
    font-size: 1.4rem;
  }

  &.red {
    background-color: vars.$red;

    &:hover,
    &.viewed {
      background-color: vars.$red-hover;
    }
  }
}

.utils {
  display: inline-block;
  vertical-align: middle;
  margin: 4px 0;
  width: 100%;

  .util-link {
    @include mixins.border-radius(3px);
    color: #FFF;
    text-decoration: none;
    float: right;
    line-height: 24px;
    display: inline-block;
    font-size: 1.4rem;
    padding: 3px 10px;

    &.red {
      background-color: vars.$red;

      &:hover {
        background-color: vars.$red-hover;
      }
    }

    &.green {
      background-color: vars.$green;

      &:hover {
        background-color: vars.$green-hover;
      }
    }
  }
}

.form-btn {
  padding: 10px 20px;
  font-size: 1.4rem;
  letter-spacing: 1px;
}

.pulse-button {
  box-shadow: 0 0 0 0 rgba(#006a2e, .5);
  -webkit-animation: pulsebtn 3s 3;

  &:hover, &:focus &:focus-within, &:active {
    -webkit-animation: none;
  }
}
